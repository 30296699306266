import React, { useEffect } from 'react';
import './styles.scss';

// components
import Hello from 'components/Hello';

const Home = () => {
  // Set the page title and position using the useEffect hook
  useEffect(() => {
    document.title = `Pacific Life • Home`;
    window.scrollTo(0, 0);
  });

  return <Hello />;
};

export default Home;
