import React from 'react';
import './scss/main.scss';

import Home from 'pages/Home';

const App = () => (
  <>
    <Home />
  </>
);

export default App;
